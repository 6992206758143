<template>
    <div>
        <h1>Sales Scorecard</h1>
        <form @submit.prevent="runReport">
            <Options />
            <RptNav :csv="false" :pdf="false" :drive="false" />
            <Results @clicked="setSortParams" />
        </form>
    </div>
</template>

<script>

import Results from "@/components/reports/utils/Results.vue";
import Options from "@/components/reports/sales_scorecard_opt.vue";
import { store } from '@/store/Report.store.js'
import DateHelpers from "@/store/DateHelpers";
import RptNav from "@/components/reports/utils/RptNav";

export default {
    name: "sales_scorecard",
    components: {
        Results,
        Options,
        RptNav
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                startDate: {val: DateHelpers.getFirstOfTheYear(), qsField: '[start_date]'},
                endDate: {val: '', qsField: '[end_date]'},
                cdiLocationID: {val: 0, qsField: '[cdi_location_id]'},
                territoryID: {val: 0, qsField: '[territory_id]'},
                strategy: {val: 'year_over_year', qsField: '[strategy]'},
                numberOfPeriods: {val: 2, qsField: '[number_of_periods]'},
                includeNational: {val: null, qsField: '[include_national]'}
            }
        }
    },
    methods: {
        runReport: function() {
            store.runReport(this.$options.name, this.optionFields);
        },
        setSortParams: function(el) {
            if (el.getAttribute('cdi_value') == null) return;
            let currentSort = this.state.optionFields.orderBy.val;

            if (currentSort == el.getAttribute('cdi_value').trim()) {
                this.state.optionFields.direction.val = this.state.optionFields.direction.val == 'asc' ? 'desc' : 'asc';
            } else {
                this.state.optionFields.direction.val = 'asc';
            }
            this.state.optionFields.orderBy.val = el.getAttribute('cdi_value').trim();
            
            this.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Sales Scorecard']);
        this.$set(store.state.results, this.$options.name, '');
        this.optionFields.cdiLocationID.val = this.$appStore.state.user.defaultLocationID;
        store.setReport(this.$options.name, this.optionFields, this.groupByFields,this.dataFields, false, false);
    }
}

</script>